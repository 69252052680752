import { Controller } from "@hotwired/stimulus";
import { dawaAutocomplete } from 'dawa-autocomplete2';

export default class extends Controller {
  static targets = ['input', 'zip', 'streetName', 'streetNumber', 'place', 'premise', 'latitude', 'longitude'];

  connect() {
    dawaAutocomplete(this.inputTarget, {
      select: this.onAreaSelected,
      ctrl: this
    });
  }

  onAreaSelected(selected) {
    const areaLookupResult = selected.data;

    this.ctrl.zipTarget.value = areaLookupResult.postnr;
    this.ctrl.streetNameTarget.value = areaLookupResult.vejnavn;
    this.ctrl.streetNumberTarget.value = areaLookupResult.husnr;
    this.ctrl.placeTarget.value = areaLookupResult.postnrnavn;
    this.ctrl.premiseTarget.value = [areaLookupResult.etage, areaLookupResult['dør']].filter(x => x !== null).join(' ');
    this.ctrl.latitudeTarget.value = areaLookupResult['y'] || '';
    this.ctrl.longitudeTarget.value = areaLookupResult['x'] || '';

    const jobZipPostalElement = document.getElementById('job_job_zips_attributes_0_zip');
    const jobZipLatitudeElement = document.getElementById('job_job_zips_attributes_0_latitude');
    const jobZipLongitudeElement = document.getElementById('job_job_zips_attributes_0_longitude');

    if (jobZipPostalElement && jobZipLatitudeElement && jobZipLongitudeElement) {
      jobZipPostalElement.value = areaLookupResult['postnr'] || '';
      jobZipLatitudeElement.value = areaLookupResult['y'] || '';
      jobZipLongitudeElement.value = areaLookupResult['x'] || '';

      const carOwnerFieldCtrlElement = document.getElementById(this.ctrl.inputTarget.dataset.carOwnerFieldCtrlId);

      if (carOwnerFieldCtrlElement) {
        const carOwnerFieldCtrl =
        this.ctrl.application.getControllerForElementAndIdentifier(
          carOwnerFieldCtrlElement,
          'car-owner-field'
        );

        if (carOwnerFieldCtrl)
          carOwnerFieldCtrl.displaySuccess();
      }
    }
  }
}
