import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input', 'feedbackGlyph', 'requiredError', 'tooShortError', 'emailError', 'initialError'];

  connect() {
    if (this.hasInitialErrorTarget) {
      this.inputTarget.value = '';
      this.displayError();
      this.initialErrorTarget.classList.remove('hidden');
      return;
    }

    if (this.element.dataset.editProfile != 'true') {
      const initialValue = this.inputTarget.value;
      if (initialValue.length > 0) {
        this.validate();
      }
    }

    if (this.inputTarget.id == 'jobEmail') {
      this.inputTarget.addEventListener( )
    }
  }

  validateRegex(evt) {
    const input = evt.target;
    const regex = input.dataset.regex;

    if (!regex) return;

    if (!input.value.match(regex))
      input.value = input.value.slice(0, -1);
  }

  validate(evt) {
    const minLength = this.inputTarget.dataset.minLength;
    const maxLength = this.inputTarget.dataset.maxLength;

    if (this.element.dataset.required == 'false') {
      return this.displaySuccess();
    }

    if (this.inputTarget.value == '') {
      this.displayError();
      this.tooShortErrorTarget.classList.add('hidden');
      this.requiredErrorTarget.classList.remove('hidden');
      return;
    } else if (minLength && this.inputTarget.value.length < minLength) {
      this.displayError();
      this.tooShortErrorTarget.classList.remove('hidden');
      return;
    } else if (maxLength && this.inputTarget.value.length > maxLength) {
      this.displayError();
      this.tooShortErrorTarget.classList.add('hidden');
      this.requiredErrorTarget.classList.remove('hidden');

      return;
    } else if (minLength && this.inputTarget.value.length >= minLength) {
      this.tooShortErrorTarget.classList.add('hidden');
      this.displaySuccess();

      return;
    } else if (this.hasEmailErrorTarget) {
      if (!this.validEmail()) {
        this.displayError();
        this.emailErrorTarget.classList.remove('hidden');
      } else if (this.signedIn) {
        this.searchEmail();
      } else {
        return this.displaySuccess();
      }
    } else if (this.inputTarget.dataset.carOwnerTarget == 'placeInput') {
      if (this.validArea()) {
        this.searchArea();
      } else {
        this.displayError();
      }
    } else {
      return this.displaySuccess();
    }
  }

  resetVisuals() {
    this.requiredErrorTarget.classList.add('hidden');
    this.hasEmailErrorTarget && this.emailErrorTarget.classList.add('hidden');
    this.hasInitialErrorTarget && this.initialErrorTarget.classList.add('hidden');
    this.element.classList.remove('has-feedback');
    this.element.classList.remove('has-error');
    this.element.classList.remove('has-success');
    this.feedbackGlyphTarget.classList.add('hidden');
    this.feedbackGlyphTarget.classList.remove('glyphicon-remove');
    this.feedbackGlyphTarget.classList.remove('glyphicon-ok');
  }

  displaySuccess() {
    this.resetVisuals();
    this.element.classList.add('has-feedback');
    this.element.classList.add('has-success');
    this.feedbackGlyphTarget.classList.remove('hidden');
    this.feedbackGlyphTarget.classList.add('glyphicon-ok');

    const fieldWithError = this.element.querySelector('.field_with_errors')
    if (fieldWithError){
      fieldWithError.classList.remove('field_with_errors');
    }
  }

  displayError() {
    this.resetVisuals();
    this.element.classList.add('has-feedback');
    this.element.classList.add('has-error');
    this.feedbackGlyphTarget.classList.remove('hidden');
    this.feedbackGlyphTarget.classList.add('glyphicon-remove');
  }

  validEmail() {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return this.inputTarget.value.match(mailformat);
  }

  searchEmail() {
    const emailUrl = this.inputTarget.dataset.url;

    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: `${emailUrl}?email=${this.inputTarget.value}`,
      success: (result) => {
        if (result.taken) {
          this.displayError();
        } else {
          this.displaySuccess();
        }
      }
    });
  }

  get hasEmailErrorTarget() {
    return !!this.element.querySelector('[data-car-owner-field-target="emailError"]');
  }

  get hasInitialErrorTarget() {
    return !!this.element.querySelector('[data-car-owner-field-target="initialError"]');
  }
}
