setTimeout(() => {
  document.querySelectorAll('.cms-page').forEach(pageElement => {
    const showEditButtons = pageElement.dataset.showEditButtons === 'true';

    if (showEditButtons) {
      const pageId = pageElement.dataset.pageId;
      pageElement.querySelectorAll('.page-segment').forEach(segmentElement => {
        const segmentPageId = segmentElement.dataset.pageId;
        const segmentId = segmentElement.dataset.segmentId;


        const editUrl = '/admin/cms/pages/' + segmentPageId + '/segments/' + segmentId + '/edit?redirect_to_page_id=' + pageId;
        const editButton = '<a href="' + editUrl + '" class="page-segment-edit-button absolute right-0 mr-2 bg-ab-orange-700 hover:bg-ab-orange-400 shadow-xl rounded my-2 px-6 py-4 text-white">&#9998</a>';

        segmentElement.innerHTML = editButton + segmentElement.innerHTML;
      })
    }
  });
}, 200);
