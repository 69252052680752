import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['container'];

  connect() {
    // The class we should toggle on the container
    this.toggleClass = this.data.get('class') || 'hidden';

    // The HTML for the background element
    this.backgroundHtml = this.data.get('backgroundHtml') || this._backgroundHTML();

    // The ID of the background to hide/remove
    this.backgroundId = this.data.get('backgroundId') || `modal-background-${Math.random().toString(36).substr(2, 9)}`;

    // Let the user close the modal by clicking on the background
    this.allowBackgroundClose = (this.data.get('allowBackgroundClose') || 'true') === 'true';

    this.stateListeners = [];

    this.opened = this.data.get('opened') === 'true';

    if (this.opened) {
      this.open();
    }
  }

  disconnect() {
    // Only close if this modal is still open
    if (this.opened) {
      this.close();
    }
  }

  open(e) {
    if (e) {
      e.preventDefault();
      e.target.blur();
    }

    // Count currently open modals to manage z-index
    const openModals = document.querySelectorAll('.modal-backdrop-active').length;
    const zIndex = 50 + (openModals * 10);

    // Lock the scroll and save current scroll position
    // Only lock if this is the first modal
    if (openModals === 0) {
      this.lockScroll();
    }

    // Insert the background
    if (!this.data.get('disable-backdrop')) {
      document.body.insertAdjacentHTML('beforeend', this._backgroundHTML(zIndex));
      this.background = document.querySelector(`#${this.backgroundId}`);
      this.background.classList.add('modal-backdrop-active');
    }

    // Unhide the modal and set z-index - MUCH higher than background
    this.containerTarget.classList.remove(this.toggleClass);
    this.containerTarget.style.zIndex = zIndex + 50; // Use a larger offset to ensure it's above backdrop

    this.opened = true;
    this.triggerStateListeners();
  }

  close(e) {
    if (e) e.preventDefault();

    // Check if there are other open modals
    const openModals = document.querySelectorAll('.modal-backdrop-active').length;

    // Unlock the scroll and restore previous scroll position
    // Only unlock if this is the last modal
    if (openModals <= 1) {
      this.unlockScroll();
    }

    // Hide the modal
    this.containerTarget.classList.add(this.toggleClass);

    // Remove the background
    if (this.background) {
      this.background.remove();
    }

    this.opened = false;
    this.triggerStateListeners();
  }

  addStateListener(listener) {
    this.stateListeners.push(listener);
  }

  triggerStateListeners() {
    this.stateListeners.forEach(listener => {
      listener(this, this.opened);
    });
  }

  closeBackground(e) {
    // Only close if clicking directly on the container element (the backdrop area)
    // and not on any of its children
    if (this.allowBackgroundClose && e.target === this.containerTarget) {
      // Find the modal with the highest z-index
      const allBackdrops = document.querySelectorAll('.modal-backdrop-active');

      // Only close if this is the top-most modal
      if (allBackdrops.length > 0) {
        const highestZIndexBackdrop = Array.from(allBackdrops).sort((a, b) => {
          return parseInt(b.style.zIndex || 0, 10) - parseInt(a.style.zIndex || 0, 10);
        })[0];

        if (this.background === highestZIndexBackdrop) {
          this.close(e);
        }
      } else {
        this.close(e);
      }
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
      // Find all open modals
      const allBackdrops = document.querySelectorAll('.modal-backdrop-active');
      
      // Only close if this is the top-most modal (with highest z-index)
      if (allBackdrops.length > 0) {
        const highestZIndexBackdrop = Array.from(allBackdrops).sort((a, b) => {
          return parseInt(b.style.zIndex || 0, 10) - parseInt(a.style.zIndex || 0, 10);
        })[0];
        
        if (this.background === highestZIndexBackdrop) {
          this.close(e);
        }
      } else {
        this.close(e);
      }
    }
  }

  _backgroundHTML(zIndex = 50) {
    return `<div id="${this.backgroundId}" class="fixed top-0 left-0 w-full h-full" style="background-color: rgba(0, 0, 0, 0.8); z-index: ${zIndex}; pointer-events: none;"></div>`;
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Save the scroll position
    this.saveScrollPosition();

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden');

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden');

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition();

    // Remove the negative top inline style from body
    document.body.style.top = null;
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition;
  }
}
