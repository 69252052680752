import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "closeButton", "carOwnerPromotionImageLink", "carOwnerPromotionReadMoreLink", "carOwnerPromotionLink", "carOwnerPromotionDisclaimerLink"];

  connect() {
    this.adminPresent = this.data.get("admin-present");
    this.delayTimer = this.data.get("delay-timer");
    this.periodId = this.data.get("period-id");
    this.requestPath = this.data.get("request-path");
    this.requestHost = this.data.get("request-host");

    setTimeout(() => {
      this.openModal();
    }, this.delayTimer);

    if (this.adminPresent == "false") {
      const addClickListener = (element, event) => {
        element.addEventListener("click", () => {
          this.markCarOwnerPromotionPeriodHandled(this.periodId, event);
          if (element == this.closeButtonTarget) {
            this.closeModal();
          }
        });
      };

      this.carOwnerPromotionLinkTargets.forEach((link) => {
        addClickListener(link, "cta_clicked");
      });

      if (this.hasCarOwnerPromotionImageLinkTarget) {
        addClickListener(this.carOwnerPromotionImageLinkTarget, "image_clicked");
      }

      addClickListener(this.closeButtonTarget, "closed");
      addClickListener(this.carOwnerPromotionDisclaimerLinkTarget, "disclaimer_clicked");

      if (this.hasCarOwnerPromotionReadMoreLinkTarget) {
        addClickListener(this.carOwnerPromotionReadMoreLinkTarget, "read_more_clicked");
      }
    } else {
      this.closeButtonTarget.addEventListener("click", () => {
        this.closeModal();
      });
    }
  }

  openModal() {
    this.modalTarget.style.display = "block";
  }

  closeModal() {
    this.modalTarget.style.display = "none";
  }

  markCarOwnerPromotionPeriodHandled(periodId, event) {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch(`/marketing/car_owner_promotion_activities?period_id=${periodId}&event=${event}&request_path="${this.requestPath}"`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': token
      },
    });
  }
}
