import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['toggleable'];

  connect() {
    this.toggleClass = this.data.get('class')
  }

  toggle(event) {
    if(!this.data.get('process-default'))
      event.preventDefault();

    this.toggleableTargets.forEach(target => {
      const cssClasses = target.dataset.toggleClass || this.toggleClass || 'hidden';

      cssClasses.split(' ').forEach(cssClass => {
        target.classList.toggle(cssClass);
      });
    });

    if (this.data.get('scrollToElement') == 'true'){
      this.element.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}
