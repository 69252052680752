import "@hotwired/turbo-rails";
import { Application } from "@hotwired/stimulus"


import ModalController from "../shared/modal_controller"
import FindMechanicsSearchController from "../shared/find_mechanics_search_controller"
import CustomAutocompleteController from "../shared/custom_autocomplete_controller"
import DropdownController from "../shared/dropdown_controller"
import ToggleController from "../shared/toggle_controller"
import NavigationController from "../shared/navigation_controller"
import CarOwnerPromotionController from "../shared/car_owner_promotion_controller"
import CarOwnerFieldController from "../shared/car_owner_field_controller"
import DawaLookupController from "../shared/dawa_lookup_controller"
import PopperController from "../shared/popper_controller"

import './icons';

import "./admin_segments"

require('rails-ujs').start()

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

application.register("modal", ModalController)
application.register("find-mechanics-search", FindMechanicsSearchController)
application.register("custom-autocomplete", CustomAutocompleteController)
application.register("dropdown", DropdownController)
application.register("toggle", ToggleController)
application.register("navigation", NavigationController)
application.register("car-owner-promotion", CarOwnerPromotionController)
application.register("car-owner-field", CarOwnerFieldController)
application.register("dawa-lookup", DawaLookupController)
application.register("popper", PopperController)
