import { config, library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faCar,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronCircleRight,
  faStar,
  faStarHalfAlt,
  faPhone,
  faPhoneAlt,
  faPaperPlane,
  faMapMarkerAlt,
  faCaretDown,
  faSearch,
  faUser,
  faCog,
  faBell,
  faSignOutAlt,
  faRss,
  faBars,
  faHeart,
  faWrench,
  faCommentsDollar,
  faVideo,
  faAngleUp,
  faLock,
} from '@fortawesome/free-solid-svg-icons';

import {
  faStar as faStarRegular,
} from '@fortawesome/free-regular-svg-icons';

import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

library.add(faCar, faStarHalfAlt, faChevronDown, faChevronLeft, faChevronRight, faChevronCircleRight, faStar, faPhone, faPhoneAlt, faPaperPlane, faFacebookF, faLinkedinIn, faTwitter, faYoutube, faMapMarkerAlt, faCaretDown, faSearch, faUser, faCog, faBell, faSignOutAlt, faBars, faRss, faHeart, faWrench, faCommentsDollar, faVideo, faAngleUp, faStarRegular,faLock);
dom.watch();
